var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper-slide",
    {
      ref: "wrapper",
      staticClass: "cp un_sel",
      style: { height: `${_vm.slide_height}` },
    },
    [
      _c(
        "div",
        {
          staticClass: "views_shopping_mail_modules_swiper_featured_slide bbox",
        },
        [
          _c("img", {
            staticClass: "img",
            attrs: { src: `${_vm.$img_url}${_vm.data.pic}`, alt: "" },
          }),
          _vm.tag && _vm.tag_name
            ? _c("div", { staticClass: "tag" }, [_vm._v(_vm._s(_vm.tag_name))])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }